body {
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}
p {
  margin: 0;
}
ul.navbar-nav {
  padding-left: 30px;
}
button.btn {
  padding: 0 .75rem;
}
.table tr td:last-child {
  width: 165px;
}
.form-control:focus {
  box-shadow: none!important;
}
.card {
  border: none;
  border-radius: 10px;
  box-shadow: 0 0 15px #eaeaea;
  padding: 20px;
}
.menu-back {
  justify-content: flex-end;
}
.custom-file-label {
  margin-top: 32px;
}
.custom-file input  {
  cursor: pointer;
}
.custom-file {
  display: inherit;
  width: inherit;
  margin-bottom: 50px;
}
.submit-btn {
  background: linear-gradient(180deg, #508f8d, #3e5e60);
  height: 45px;
  color: #FFFFFF;
  border: 1px solid #ced4d9;
  transition: all 0.2s ease-in-out;
}
.submit-btn:hover {
  background: linear-gradient(180deg, #6eb8b6, rgb(66, 111, 109));
  transition: all 0.2s ease-in-out;
}
.navbar-dark .navbar-nav .nav-link {
  color: rgba(255,255,255,0.25);
}
.navbar-dark .navbar-nav .nav-link:hover {
  color: rgba(255,255,255,0.5);
}
.navbar-nav a.active {
  color: rgba(255,255,255,1);
}
